import React from 'react';
import { Container, Typography, Box, List, ListItem, ListItemText } from '@mui/material';

const PrivacyPolicy: React.FC = () => {
  return (
    <Container maxWidth="md" sx={{ py: 8 }}>
      <Typography variant="h3" component="h1" gutterBottom>
        Privacy Policy
      </Typography>
      <Typography variant="body1" paragraph>
        Last updated: 13 October 2024
      </Typography>

      <Box component="section" mb={6}>
        <Typography variant="h4" component="h2" gutterBottom>
          1. Introduction
        </Typography>
        <Typography variant="body1" paragraph>
          Welcome to bookly.one (&quot;we,&quot; &quot;our,&quot; or &quot;us&quot;). We are committed to protecting
          your privacy and personal information. This Privacy Policy explains how we collect, use, disclose, and
          safeguard your information when you use our service.
        </Typography>
      </Box>

      <Box component="section" mb={6}>
        <Typography variant="h4" component="h2" gutterBottom>
          2. Information We Collect
        </Typography>
        <Typography variant="body1" paragraph>
          We collect information that you provide directly to us, such as when you create an account, use our services,
          or contact our support team. This may include:
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="Name and contact information" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Account credentials" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Payment information" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Usage data and preferences" />
          </ListItem>
        </List>
      </Box>

      <Box component="section" mb={6}>
        <Typography variant="h4" component="h2" gutterBottom>
          3. How We Use Your Information
        </Typography>
        <Typography variant="body1" paragraph>
          We use your information to:
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="Provide, maintain, and improve our services" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Process transactions and send related information" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Send you technical notices, updates, and support messages" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Respond to your comments and questions" />
          </ListItem>
        </List>
      </Box>

      <Box component="section" mb={6}>
        <Typography variant="h4" component="h2" gutterBottom>
          4. Data Security
        </Typography>
        <Typography variant="body1" paragraph>
          We implement appropriate technical and organizational measures to protect your personal information against
          unauthorized or unlawful processing, accidental loss, destruction, or damage.
        </Typography>
      </Box>

      <Box component="section" mb={6}>
        <Typography variant="h4" component="h2" gutterBottom>
          5. Your Rights
        </Typography>
        <Typography variant="body1" paragraph>
          You have the right to access, correct, or delete your personal information. You may also have the right to
          restrict or object to certain processing of your data. To exercise these rights, please contact us using the
          information provided below.
        </Typography>
      </Box>

      <Box component="section" mb={6}>
        <Typography variant="h4" component="h2" gutterBottom>
          6. Changes to This Privacy Policy
        </Typography>
        <Typography variant="body1" paragraph>
          We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new
          Privacy Policy on this page and updating the &quot;Last updated&quot; date.
        </Typography>
      </Box>

      <Box component="section">
        <Typography variant="h4" component="h2" gutterBottom>
          7. Contact Us
        </Typography>
        <Typography variant="body1" paragraph>
          If you have any questions about this Privacy Policy, please contact us at:
        </Typography>
        <Typography variant="body1" component="p">
          bookly.one
          <br />
          Email: akhmadounesoud@gmail.com
        </Typography>
      </Box>
    </Container>
  );
};

export default PrivacyPolicy;
