import React from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Container, Typography, TextField, Button, Box } from '@mui/material';

interface IFormInputs {
  name: string;
  email: string;
  message: string;
}

const SupportPage: React.FC = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IFormInputs>();

  const onSubmit: SubmitHandler<IFormInputs> = (data) => {
    reset();
    alert('Form submitted');
  };

  return (
    <Container maxWidth="sm" sx={{ py: 8 }}>
      <Typography variant="h3" component="h1" gutterBottom>
        Support
      </Typography>
      <Typography variant="body1" paragraph>
        Need help? Please fill out the form below and we will get back to you as soon as possible.
      </Typography>

      <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
        <TextField
          margin="normal"
          required
          fullWidth
          id="name"
          label="Name"
          {...register('name', { required: 'Name is required' })}
          error={!!errors.name}
          helperText={errors.name?.message}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email"
          type="email"
          {...register('email', {
            required: 'Email is required',
            pattern: {
              value: /\S+@\S+\.\S+/,
              message: 'Entered value does not match email format',
            },
          })}
          error={!!errors.email}
          helperText={errors.email?.message}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="message"
          label="Message"
          multiline
          rows={4}
          {...register('message', { required: 'Message is required' })}
          error={!!errors.message}
          helperText={errors.message?.message}
        />
        <Button size="large" type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
          Submit
        </Button>
      </Box>
    </Container>
  );
};

export default SupportPage;
